import React from "react"

import Layout from "../components/layout"

const SubscriptionSuccess = () => (
  <Layout>
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 bg-gray-100 shadow">
        <div className="px-4 py-6 sm:px-0">
          <div className="border-2 border-gray-200 rounded-lg h-96 bg-white">
            <div className="container mx-auto m-24">
              <div className="flex justify-center">
                <div className="prose prose-sm color-mine-shaft">
                  <h1>Thank you for subscribing!</h1>
                  <p>Now, fire up your terminal and:
                    <ul>
                      <li>For Hydra follow the instructions <a href="https://docs.klevoya.com/hydra/about/getting-started">here</a></li>
                      <li>For Inspect follow the instructions <a href="https://docs.klevoya.com/inspect/about/getting-started">here</a></li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </Layout>
)

export default SubscriptionSuccess
